



































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Header extends Vue {
  menuItems: any[] = [
    {
      name: "首页",
      href: "bannerBox",
      route: "home"
    },
    // {
    //   name: "产品服务",
    //   href: "serviceBox",
    //   route: "home"
    // },
    // {
    //   name: "城市合伙人",
    //   href: "partnerBox",
    //   route: "home"
    // },
    {
      name: "产品介绍",
      route: "introduce"
    },
    {
      name: "企业用户申请",
      route: "register"
    }
  ];
  isLogin = false;

  switchRoute(item: any) {
    if (item.href) {
      this.$emit("changeLocation", item.href);
    }
    if (`${this.$route.name}` != item.route) {
      this.$router.push({
        name: item.route,
        params: {
          href: item.href
        }
      });
    }
  }

  goLogin() {
    window.open("http://sys.lemonstea.com", "_blank");
    // if (this.isLogin) {
    //   return;
    // }
    // this.switchRoute({
    //   path: "login"
    // });
  }

  logout() {
    console.log("logout");
  }
}
